import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './Context/AuthContext';
import { ThemeContextProvider } from './Context/ThemeContext';
import { BrowserRouter } from 'react-router-dom';
import { AxiosInterceptor } from './Helper/api_helper';
import { ElectionContextProvider } from './Context/ElectionContext';
import { VotingContextProvider } from './Context/VotingContext';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-8CBFJK8XDF');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.Fragment>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ThemeContextProvider>
        <AuthContextProvider>
          <ElectionContextProvider>
            <VotingContextProvider>
              <AxiosInterceptor>
                <App />
              </AxiosInterceptor>
            </VotingContextProvider>
          </ElectionContextProvider>
        </AuthContextProvider>
      </ThemeContextProvider>
    </BrowserRouter>
  </React.Fragment>,
);
