import { useAuthContext } from 'Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { removeAuthorization } from 'Helper/api_helper';
import { useElectionContext } from 'Context/ElectionContext';
import { useVotingContext } from 'Context/VotingContext';

export const useLogout = () => {
  const { dispatch } = useAuthContext();
  const { dispatch: electionDispatch } = useElectionContext();
  const { dispatch: votingDispatch } = useVotingContext();
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem('token');
    dispatch({ type: 'LOGOUT' });
    electionDispatch({ type: 'CLEAR_CONTEXT' });
    votingDispatch({ type: 'CLEAR_CONTEXT' });
    navigate('/');
    removeAuthorization();
  };
  return { logout };
};
