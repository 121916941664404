import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';
import AllRoutes from './AllRoutes/Routes';
import { ToastContainer } from 'react-toastify';
import './assets/styles/style.scss';
import { useTheme } from 'Hooks/useTheme';
import { theme, breakpoints } from 'Constants/theme-const';

function App() {
  document.title = 'Nasscom Election';
  const location = useLocation();
  const { setScreenSize } = useTheme();
  const triggerResize = () => {
    if (window.innerWidth <= breakpoints.sm) {
      setScreenSize('xs');
    } else if (window.innerWidth <= breakpoints.md) {
      setScreenSize('sm');
    } else if (window.innerWidth <= breakpoints.lg) {
      setScreenSize('md');
    } else if (window.innerWidth <= breakpoints.xl) {
      setScreenSize('lg');
    } else {
      setScreenSize('xl');
    }
  };

  useEffect(() => {
    triggerResize();
    window.addEventListener('resize', triggerResize);
    return () => {
      window.removeEventListener('resize', triggerResize);
    };
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: document.title, path: location.pathname });
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <AllRoutes />
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
