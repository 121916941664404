import { createContext, useReducer, useContext } from 'react';
export const VotingContext = createContext();
const initialState = {
  value: 'General',
  selected: {},
  count: 0,
};
export const votingReducer = (state, action) => {
  switch (action.type) {
    case 'SET_VOTES_VALUE':
      return { ...state, value: action.payload };
    case 'SET_VOTES':
      return { ...state, selected: action.payload };
    case 'CLEAR_CONTEXT':
      return { ...initialState };
    default:
      return state;
  }
};

export const VotingContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(votingReducer, {});

  return <VotingContext.Provider value={{ ...state, dispatch }}>{children}</VotingContext.Provider>;
};

export const useVotingContext = () => {
  const context = useContext(VotingContext);
  if (!context) {
    throw Error('useVotingContext must be used inside an VotingContextProvider');
  }
  return context;
};
